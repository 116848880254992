<template>
    <v-container>
        <ValidationObserver v-slot="{ handleSubmit }">
            <v-tabs v-model="tabIndex">
                <v-tab v-for="item in items" :key="item.id">{{
                    item.tab
                    }}
                </v-tab>
            </v-tabs>
            <v-tabs-items class="tab-content " v-model="tabIndex">
                <v-tab-item active>
                    <t-ask-content class="tab-detail"
                                   :grades="grades"
                                   :subjects="subjects"
                                   :ask="detailAsk"
                                   @delImage="delImage = $event">
                    </t-ask-content>
                </v-tab-item>
                <v-tab-item>
                    <t-ask-seo class="tab-detail" :ask="detailAsk"></t-ask-seo>
                </v-tab-item>
            </v-tabs-items>
            <v-row class="align-center justify-center">
                <v-btn color="primary" @click="handleSubmit(updateLesson)">Cập nhật câu hỏi</v-btn>
            </v-row>
            <v-dialog
                    v-model="dialog"
                    max-width="600px"
                    min-height="600px"
            >
            </v-dialog>
        </ValidationObserver>
    </v-container>
</template>
<script>
import {mapGetters} from "vuex";

// stores
import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
import {SET_LOADING} from "@/store/loading.module";
import {SAVE_ASK, UPDATE_ASK, DETAIL_ASK} from "@/store/ask.module";
import {GET_LIST_SUBJECTS} from "@/store/subject.module";
import {GET_LIST_GRADE} from "@/store/grade.module";
import {SUCCESS, ERROR} from "@/store/alert.module";
// components
import TAskContent from "@/components/ask/TAskContent";
import TAskSeo from "@/components/ask/TAskSeo";
// import HnrInput from '@/components/elements/hnr-input'
// import HnrEditTable from '@/components/elements/hnr-edit-table'

export default {
    data() {
        return {
            detailAsk: {
                id: '',
                title: "",
                slug: "",
                content: "",
                seo_title: "",
                seo_description: "",
                seo_key: ""
            },
            slug: null,
            isRedirect: true,
            tabIndex: 0,
            items: [
                {id: 0, tab: "Thông tin"},
                {id: 1, tab: "Cấu hình SEO"},
            ],
            categories: [],
            dialog: false,
            headers: [
                {
                    text: 'id',
                    align: 'start',
                    sortable: true,
                    value: 'id',
                },
                {
                    text: 'Name',
                    align: 'start',
                    sortable: true,
                    value: 'name'
                }
            ],
            delImage: false
        };
    },
    components: {
        // HnrInput,
        TAskContent,
        TAskSeo,
        // HnrEditTable
    },
    computed: {
        ...mapGetters({
            ask: "detailAsk",
            subjects: "listSubjects",
            grades: "listGrade",
        }),
    },
    watch: {
        ask: {
            deep: true,
            immediate: true,
            handler(newVal) {
                this.detailAsk = newVal;
            },
        },
    },
    created() {
        this.slug = this.$route.params.slug;
        this.$store.dispatch(GET_LIST_SUBJECTS)
        this.$store.dispatch(GET_LIST_GRADE)
        this.resetAsk()
        if (this.slug) {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.slug}]);
            this.$store.dispatch(SET_LOADING, true);
            this.$store.dispatch(DETAIL_ASK, this.slug).then(() => {
                this.$store.dispatch(SET_LOADING, false);
            }).catch(() => {
                this.$store.dispatch(SET_LOADING, false);
            });
        }
    },

    methods: {
        resetAsk() {
            this.detailAsk = {}
        },
        regexImageUrl(content) {
            if (!content) {
                return null;
            }
            content = content.replace(
                /<img([\w\W]+?)>/gi,
                function (match) {
                    match = match.replace(
                        /src\s*=\s*"/g,
                        function () {
                            return 'src="' + process.env.VUE_APP_BASE_URL + "/";
                        }
                    );
                    return match;
                }
            );
            return content;
        },
        updateLesson() {
            this.isRedirect = false;
            this.$store.dispatch(SET_LOADING, true);
            if (this.detailAsk.subject) {
                this.detailAsk.subject_id = this.detailAsk.subject.id;
                this.detailAsk.subject = null;
            }
            if (this.detailAsk.grade) {
                this.detailAsk.grade_id = this.detailAsk.grade.id;
                this.detailAsk.grade = null;
            }
            let formData = null
            if (typeof this.detailAsk.image === 'object') {
                formData = new FormData()
                formData.append('grade_id', this.detailAsk.grade_id)
                formData.append('subject_id', this.detailAsk.subject_id)
                formData.append('content', this.detailAsk.content)
                formData.append('image', this.detailAsk.image)
                formData.append('seo_key', this.detailAsk.seo_key)
                formData.append('seo_title', this.detailAsk.seo_title)
                formData.append('seo_description', this.detailAsk.seo_description)
                formData.append('_method', "PATCH")
            } else if (this.delImage) {
                formData = {
                    grade_id: this.detailAsk.grade_id,
                    subject_id: this.detailAsk.subject_id,
                    image: null,
                    content: this.detailAsk.content,
                    seo_key: this.detailAsk.seo_key,
                    seo_title: this.detailAsk.seo_title,
                    seo_description: this.detailAsk.seo_description,
                    _method: "PATCH",
                }
            }else {
                formData = {
                    grade_id: this.detailAsk.grade_id,
                    subject_id: this.detailAsk.subject_id,
                    content: this.detailAsk.content,
                    seo_key: this.detailAsk.seo_key,
                    seo_title: this.detailAsk.seo_title,
                    seo_description: this.detailAsk.seo_description,
                    _method: "PATCH",
                }
            }
            let payload = {
                id: this.detailAsk.id,
                data: formData
            }
            this.$store.dispatch(UPDATE_ASK, payload).then(data => {
                if (data.status) {
                    this.$store.dispatch(SUCCESS, "Cập nhật bài viết thành công!", {root: true});
                    this.$router.push('/ask');
                } else {
                    if (data.errorCode == 424) {
                        this.$store.dispatch(ERROR, data.message, {
                            root: true,
                        });
                    } else {
                        this.$store.dispatch(ERROR, "Cập nhật bài viết thất bại!", {
                            root: true,
                        });
                    }
                }
                this.$store.dispatch(SET_LOADING, false);
            }).catch(() => {
                this.$store.dispatch(ERROR, "Cập nhật bài viết thất bại!", {root: true});
                this.$store.dispatch(SET_LOADING, false);
            });
        },
        updateImageUrl(content) {
            if (!content) {
                return null;
            }
            content = content.replace(
                /<img([\w\W]+?)>/gi,
                function (match) {
                    match = match.replace(
                        /src="([^"]+)"/g,
                        function (match_child) {
                            let base_url = process.env.VUE_APP_BASE_URL
                            var regex = new RegExp(base_url + "/(.*)", "");
                            let relative_path = "";
                            let relative_path_arr = match_child.match(regex)
                            if (relative_path_arr.length > 0) {
                                relative_path = relative_path_arr[1];
                                return 'src="' + relative_path;
                            }
                        }
                    );
                    return match;
                }
            );
            return content;
        },

        addRow() {
            this.dialog = true;
        },
        updateItemTable(payload) {
            var index = this.categories.findIndex(item => item.id == payload.id);
            this.categories[index] = payload;
        },
        // deleteItem(item) {
        //   if (confirm("Are you sure you want to delete this item?")) {
        //     let payload = { news_id: this.detailAsk.id, news_category_id: item.id };
        //
        //     this.$store.dispatch(SET_LOADING, true);
        //     this.$store.dispatch(DELETE_CATEGORY_LESSON, payload).then(data => {
        //       if (data.status) {
        //         this.$store.dispatch(SUCCESS, "Xóa chủ đề thành công!", { root: true });
        //       }
        //       else {
        //         this.$store.dispatch(ERROR, "Xóa chủ đề thất bại!", { root: true });
        //       }
        //       this.$store.dispatch(SET_LOADING, false);
        //     }).catch(() => {
        //       this.$store.dispatch(ERROR, "Xóa chủ đề thất bại!", { root: true });
        //       this.$store.dispatch(SET_LOADING, false);
        //     });
        //   }
        // },

    },
    beforeRouteLeave(to, from, next) {
        if (this.isRedirect) {
            if (confirm("Bạn chưa lưu bài viết.Bạn vẫn muốn rời đi chứ?")) {
                next()
            } else {
                next(false)
            }
        } else {
            next();
        }
    },
};
</script>


<style lang="scss">
.tab-detail {
  margin-top: 15px;
}
</style>
