<template>
  <v-container>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Slug </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <hnr-input
            :text="detailAsk.slug"
            @input="changeSlug"
            v-model="detailAsk.slug"
            readonly
        ></hnr-input>
        <span class="validate-error text-danger text-danger">
            {{ slug_message }}
        </span>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center mt-2">
      <v-col cols="2" class="pa-1">
        <span> Môn học </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <v-select
            v-model="detailAsk.subject"
            :items="subjects"
            item-text="name"
            item-value="id"
            label="Nhập môn học"
            return-object
            dense
            outlined
        ></v-select>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center mt-2">
      <v-col cols="2" class="pa-1">
        <span> Lớp </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <v-select
            v-model="detailAsk.grade"
            :items="grades"
            item-text="name"
            item-value="id"
            label="Nhập lớp học"
            return-object
            dense
            outlined
        ></v-select>
      </v-col>
    </v-row>

    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Nội dung </span>
      </v-col>
      <v-col cols="12" class="pa-1">
        <ValidationProvider
            name="content"
            rules="required"
            v-slot="{ errors }"
        >
          <div style="border: 1px solid #c4c4c4; padding: 10px;margin-bottom: 20px">
            <math-jax-preview :formula="detailAsk.content"></math-jax-preview>
          </div>
          <ck-content
              :content="detailAsk.content"
              @getData="updateContent"
              v-model="detailAsk.content"
          ></ck-content>
          <math-jax-editor
              :formula_prop="formula"
              :image_prop="detailAsk.image"
              @addFormula="addFormula"
              @image="detailAsk.image = $event"
              @delImage="delImage"></math-jax-editor>
          <span class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </span>
        </ValidationProvider>

      </v-col>
    </v-row>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Đăng bởi </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <span v-if="detailAsk.user">{{ detailAsk.user.email }}</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import HnrInput from "@/components/elements/hnr-input";
import MathJaxEditor from "@/components/math/MathJaxEditor";
import CkContent from "@/components/ck-content";
import MathJaxPreview from "@/components/math/MathJaxPreview.vue";

export default {
  data() {
    return {
      title: "",
      formula: "",
      dialog: false,
      detailAsk: {},
      selected: [],
      value: 0,
      search: null,
      singleSelect: true,
      suggestNewCategories: [],
      headers: [
        {
          text: "id",
          align: "start",
          sortable: true,
          value: "id",
        },
        {
          text: "Name",
          align: "start",
          sortable: true,
          value: "name",
        },
      ],
    };
  },
  props: {
    ask: Object,
    subjects: Array,
    grades: Array,
    slug_message: {type: String},
  },
  watch: {
    title: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.detailAsk.title = newVal;
      },
    },
    ask: {
      deep: true,
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.detailAsk = newVal;
        }
      },
    },
    nameOfNewsCategories: {
      deep: true,
      handler(newVal) {
        this.suggestNewCategories = newVal;
      },
    },
  },
  components: {
    HnrInput,
    MathJaxEditor,
    CkContent,
    MathJaxPreview,
  },
  methods: {
    delImage() {
      this.detailAsk.image = ''
      this.$emit('delImage', true)
    },
    changeSlug() {
      this.detailAsk.has_change_slug = true;
    },
    addFormula(value) {
      this.detailAsk.content += value;
    },
    sanitizeTitle(title) {
      if (!title) {
        return null;
      }
      // Change to lower case
      var str = title.toLowerCase();
      str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
      str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
      str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
      str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
      str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
      str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
      str = str.replace(/đ/g, "d");
      // Some system encode vietnamese combining accent as individual utf-8 characters
      str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // Huyền sắc hỏi ngã nặng
      str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // Â, Ê, Ă, Ơ, Ư
      // Trim the last whitespace
      str = str.replace(/\s*$/g, '');
      // Change whitespace to "-"
      str = str.replace(/\s+/g, '-');

      str = str.replace(/\?|\(|\)|\/|!|@|#|\$|%|\^|&/g, '');
      return str;
    },
    saveNewsCategory() {
      if (this.selected && this.selected.length > 0) {
        this.detailAsk.topics = {
          id: this.selected[0].id,
          name: this.selected[0].name,
        };
      } else {
        this.detailAsk.topics = {
          id: 0,
          name: null,
        };
      }
      this.dialog = false;
    },
    getPicker(val) {
      this.detailAsk.created_at = val
    },
    updateContent(val) {
      this.detailAsk.content = val;
    },
    updateParent() {
      this.dialog = true;
    },
    update() {
      this.$emit("input");
    },
    isRealValue(obj) {
      return obj && obj !== 'null' && obj !== 'undefined';
    }
    ,
    convertNumberToString(number) {
      if (this.isRealValue(number)) {
        return number.toString();
      }
      return "0";
    },
    renderMathJax() {
      if (window.MathJax) {
        window.MathJax.Hub.Config({
          tex2jax: {
            inlineMath: [['$', '$'], ['\\(', '\\)']],
            displayMath: [['$$', '$$'], ['[', ']']],
            processEscapes: true,
            processEnvironments: true
          },
          // Center justify equations in code and markdown cells. Elsewhere
          // we use CSS to left justify single line equations in code cells.
          displayAlign: 'center',
          'HTML-CSS': {
            styles: {'.MathJax_Display': {margin: 0}},
            linebreaks: {automatic: true}
          },
          ...this.options
        })
        window.MathJax.Hub.Queue([
          'Typeset',
          window.MathJax.Hub,
          this.$refs.mathJaxEl
        ])
      }
    }
  }
  ,
}
;
</script>

<style lang="scss">
</style>
